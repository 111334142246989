import OnScreen from "../hooks/OnScreen";

const Privacy = () => {
  return (
    <>
      <OnScreen>
        <section id="accessibility">
          <div className="wrapper small">
            <div className="container column">
              <h1 className="title-alt">Déclaration d'accessibilité</h1>
              <p className="text txt">
                Kodo Wallonie, s'engage à rendre ce site accessible,
                conformément à la législation suivante : Décret du 2 mai 2019
                relatif à l'accessibilité des sites internet et des applications
                mobiles des organismes du secteur public (Région Wallonne).
              </p>
              <h2 className="subtitle">
                La présente déclaration d'accessibilité s'applique à :
              </h2>
              <p className="text txt">
                Site Web:{" "}
                <a href="https://kodowallonie.be/" target="blank">
                  https://kodowallonie.be/
                </a>
              </p>
              <h2 className="subtitle">Niveau de conformité</h2>
              <p className="text txt">
                Ce site web n'est pas conforme. Les non-conformités et les
                exemptions sont énumérées ci-dessous.
              </p>
              <h2 className="subtitle">Préparation de votre déclaration</h2>
              <p className="text txt">
                Un audit externe de l'accessibilité a été effectué. Cet audit
                consistait en une analyse simplifiée. Vous pouvez consulter le
                rapport ici :
                <a
                  href="https://scan.accessibility.belgium.be/fr/report/2024/kodowallonie.be"
                  target="blank"
                >
                  https://scan.accessibility.belgium.be/fr/report/2024/kodowallonie.be
                </a>
              </p>
              <h2 className="subtitle">Contenu non accessible</h2>
              <div>
                <p className="text txt">
                  Site réalisé par un développeur indépendant débutant qui a été
                  rectifié suite au rapport réalisé.
                </p>
              </div>
              <h2 className="subtitle">Alternatives proposées</h2>
              <div>
                <p className="text txt">
                  Modifications réalisées depuis le rapport
                </p>
              </div>
              <h2 className="subtitle">Coordonnées de contact</h2>
              <p className="text txt">
                En cas de question ou réflexion sur l'accessibilité de notre
                site/application, vous pouvez contacter :<br />
                Le service en charge de l'accessibilité peut être contacté sur{" "}
                <a href="mailto:contact@kodowallonie.be">
                  contact@kodowallonie.be
                </a>
              </p>
              <p className="text txt">
                Si le service ci-dessus ne répond pas, vous pouvez contacter le
                médiateur suivant :<br />
                N/A
              </p>
              <h2 className="subtitle">Plan d'amélioration</h2>
              <div>
                <p className="text txt">
                  Les changements ont été réalisés depuis lors.
                  <br />
                  N/A
                </p>
              </div>
              <p className="text txt">
                Cette déclaration a été préparée le 04/11/2024.
                <br />
                Le dernier examen de la déclaration a eu lieu le 04/11/2024.
              </p>
            </div>
          </div>
        </section>
      </OnScreen>
    </>
  );
};

export default Privacy;
