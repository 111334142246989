import { useNavigate } from 'react-router-dom';
import OnScreen from '../hooks/OnScreen';

const ExpFamilies = () => {
  let navigate = useNavigate();
  return (
    <>
      <OnScreen>
        <section id="cultural">
          <div className="wrapper small">
            <div className="container column">
              <h2 className="title-alt">Nos activités auprès des familles</h2>
              <p className="chapo">
                Les technologies font partie intégrante de notre quotidien et
                interrogent petits et grands sur leur utilisation, leur intérêt
                et leur pertinence dans différents contextes et notamment dans
                les familles.
              </p>
              <img className="cross" src="img/cover-02.jpg" alt="cross" />
              <span className="credit">© Julia M Cameron - Pexels</span>
              <p className="text txt">
                Kodo Wallonie œuvre au dialogue et à la sensibilisation de
                toutes et tous aux enjeux du numérique par l’information et la
                mise en expérimentation, quel que soit l’âge ou la condition
                sociale. Pour cela, nous vous proposons plusieurs moments de
                rencontres tout au long de l’année.
              </p>
              <ul>
                <li className="text txt">
                  L’équipe de Kodo Wallonie réalise des stages pour jeunes dans
                  toute la Wallonie durant les vacances scolaires. Ceux-ci ont
                  lieu dans des milieux nous accueillant tels que les espaces
                  publics numériques, les centres culturels ou encore des
                  fablabs. Nous essayons de venir dans chaque province durant
                  l’année et proposons des stages pour les 8-11 ans, 12-14 ans
                  et 15 ans et plus avec des thématiques appropriées. Dans la
                  plupart des cas, nos partenaires s’occupent des inscriptions :
                  nous pouvons vous renseigner sur les détails pratiques mais
                  vous renverrons vers eux pour le reste. Retrouvez nos stages
                  dans notre agenda.
                </li>
                <li className="text txt">
                  Nous participons régulièrement à des événements à travers
                  toute la région, quelques fois même à Bruxelles. Vous nous
                  retrouverez en animations lors du Printemps des Sciences, sur
                  les stands des Educ Days ou du SETT et dans les rencontres
                  organisées par nos partenaires tel qu’Evolu’TIC par Interface3
                  Namur. Vous retrouverez ces événements dans notre agenda si
                  nous y participons. Nous vous convions à vous informer sur les
                  détails pratiques des événements sur les sites adéquats.
                </li>
                <li className="text txt">
                  L’asbl est à votre disposition pour toute demande d’activité
                  ou d’information autour du numérique et des sciences
                  informatiques : nous pouvons vous fournir des activités sur
                  mesure à des tarifs adaptés. Par ailleurs, nous vous invitons
                  à consulter l’agenda afin de savoir où nous rencontrer et les
                  lieux de nos futurs stages. Notre page “Ressources” vous
                  permettra également de découvrir des outils (souvent gratuits)
                  et partenaires autour du numérique en fonction de vos besoins.
                </li>
              </ul>
              <h2 className="secondtitle">Notre expertise</h2>
              <ul>
                <li className="text">
                  Participation au Printemps des Sciences à Liège, Mons et Namur
                  depuis 2016.
                </li>
                <li className="text">
                  Près de 20 stages animés dans toute la Wallonie,
                  principalement dans des espaces publics numériques.
                </li>
                <li className="text">
                  Plus de 40 activités extra-scolaires proposées à toutes et
                  tous dès 7 ans.
                </li>
              </ul>
            </div>
          </div>
          <div className="wrapper">
            <div className="ma">
              <button className="link-btn" onClick={() => navigate(-1)}>
                ⇦ Retour
              </button>
            </div>
          </div>
        </section>
      </OnScreen>
    </>
  );
};

export default ExpFamilies;
