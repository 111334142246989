const Cross = () => {
  return (
    <>
      <svg x="0px" y="0px" viewBox="0 0 100 100">
        <g>
          <rect class="st1" width="100" height="100" />
        </g>
        <path
          class="st2"
          d="M55.77,50L97.81,7.96c1.59-1.59,1.59-4.18,0-5.77h0c-1.59-1.59-4.18-1.59-5.77,0L50,44.23L7.96,2.19
	c-1.59-1.59-4.18-1.59-5.77,0c-1.59,1.59-1.59,4.18,0,5.77L44.23,50L2.19,92.04c-1.59,1.59-1.59,4.18,0,5.77
	c1.59,1.59,4.18,1.59,5.77,0L50,55.77l42.04,42.04c1.59,1.59,4.18,1.59,5.77,0h0c1.59-1.59,1.59-4.18,0-5.77L55.77,50z"
        />
      </svg>
    </>
  );
};

export default Cross;
