import dataBlog from '../data/dataBlog';
import Post from '../comp/Post';
import OnScreen from '../hooks/OnScreen';
import { useNavigate } from 'react-router-dom';
import '../styles/_blog.css';

const Blog = () => {
  let navigate = useNavigate();
  return (
    <>
      <OnScreen>
        <section id="articles">
          <div className="wrapper">
            <div className="container col-3">
              {dataBlog
                .map((post, index) => (
                  <Post
                    key={'Post' + index}
                    id={post.id}
                    date={post.date}
                    author={post.author}
                    title={post.title}
                    cover={post.cover}
                  />
                ))
                .reverse()}
            </div>
          </div>
          <div className="wrapper">
            <div className="ma">
              <button className="link-btn" onClick={() => navigate(-1)}>
                ⇦ Retour
              </button>
            </div>
          </div>
        </section>
      </OnScreen>
    </>
  );
};

export default Blog;
