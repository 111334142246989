const Burger = () => {
  return (
    <>
      <svg x="0px" y="0px" viewBox="0 0 158.5 122">
        <g>
          <path
            class="st0"
            d="M150.91,108.19H60.58c-3.64,0-6.59,2.95-6.59,6.59v0c0,3.64,2.95,6.59,6.59,6.59h90.33
		c3.64,0,6.59-2.95,6.59-6.59v0C157.5,111.14,154.55,108.19,150.91,108.19z"
          />
          <path
            class="st0"
            d="M150.91,0.63H25.25c-3.64,0-6.59,2.95-6.59,6.59v0c0,3.64,2.95,6.59,6.59,6.59h125.66
		c3.64,0,6.59-2.95,6.59-6.59v0C157.5,3.58,154.55,0.63,150.91,0.63z"
          />
          <path
            class="st0"
            d="M150.91,54.41H7.59C3.95,54.41,1,57.36,1,61v0c0,3.64,2.95,6.59,6.59,6.59h143.32c3.64,0,6.59-2.95,6.59-6.59
		v0C157.5,57.36,154.55,54.41,150.91,54.41z"
          />
        </g>
      </svg>
    </>
  );
};

export default Burger;
