import EventNew from '../comp/EventNew';
import EventOld from '../comp/EventOld';
import OnScreen from '../hooks/OnScreen';
import { useNavigate } from 'react-router-dom';

const Agenda = () => {
  let navigate = useNavigate();
  return (
    <>
      <OnScreen>
        <section className="section">
          <div className="wrapper">
            <div className="section-title">
              <h2 className="title-alt">Prochains événements</h2>
            </div>
            <div className="container col-2 margin">
              <EventNew />
            </div>
          </div>
          <div className="wrapper">
            <div className="section-title">
              <h2 className="title-alt">Evénements passés</h2>
            </div>
            <div className="container col-2 margin">
              <EventOld />
            </div>
          </div>
          <div className="wrapper">
            <div className="ma">
              <button className="link-btn" onClick={() => navigate(-1)}>
                ⇦ Retour
              </button>
            </div>
          </div>
        </section>
      </OnScreen>
    </>
  );
};

export default Agenda;
