/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import { useParams } from 'react-router-dom';
import dataBlog from '../data/dataBlog';

const PostContent = () => {
  let params = useParams();
  let post = dataBlog[params.id];

  let post_elements = post.content;
  let elements = post_elements.map(function (post_element, idx) {
    if (post_element[0] == 'subtitle') {
      return (
        <p className={post_element[0]} key={idx}>
          {post_element[1]}
        </p>
      );
    } else if (post_element[0] == 'text') {
      return (
        <p className={post_element[0]} key={idx}>
          {post_element[1]}
        </p>
      );
    } else if (post_element[0] == 'quote') {
      return (
        <p className={post_element[0]} key={idx}>
          {post_element[1]}
        </p>
      );
    } else if (post_element[0] == 'bullet') {
      return (
        <p className={post_element[0]} key={idx}>
          {post_element[1]}
        </p>
      );
    } else if (post_element[0] == 'link') {
      return (
        <a
          className={post_element[0]}
          href={post_element[1]}
          target="_blank"
          rel="noreferrer"
          key={idx}
        >
          {post_element[2]}
        </a>
      );
    } else if (post_element[0] == 'image') {
      return (
        <img
          className={post_element[0]}
          src={post_element[1]}
          alt="photographie"
          key={idx}
        />
      );
    } else if (post_element[0] == 'image-f') {
      return (
        <img
          className={post_element[0]}
          src={post_element[1]}
          alt="photographie"
          key={idx}
        />
      );
    } else if (post_element[0] == 'credit') {
      return (
        <p className={post_element[0]} key={idx}>
          © {post_element[1]}
        </p>
      );
    } else if (post_element[0] == 'iframe') {
      return (
        <div className="iframe-container">
          <iframe
            title="iframe"
            className={post_element[0]}
            src={post_element[1]}
            key={idx}
          ></iframe>
        </div>
      );
    }
  });

  return <div className="bloc">{elements}</div>;
};

export default PostContent;
