import { useEffect, useRef, useState } from 'react';

const OnScreen = (props) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    });
    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [isIntersecting]);

  useEffect(() => {
    if (isIntersecting) {
      document.body.classList.add('bg-yellow');
    } else {
      document.body.classList.remove('bg-yellow');
    }
  }, [isIntersecting]);

  return (
    <>
      <span ref={ref}>{props.children}</span>
    </>
  );
};

export default OnScreen;
