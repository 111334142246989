import { useNavigate } from 'react-router-dom';
import OnScreen from '../hooks/OnScreen';

const ExpSchools = () => {
  let navigate = useNavigate();
  return (
    <>
      <OnScreen>
        <section id="schools">
          <div className="wrapper small">
            <div className="container column">
              <h2 className="title-alt">Nos activités auprès des écoles</h2>
              <p className="chapo">
                Apprendre à coder pour ne pas être programmé ! Depuis octobre
                2016, Kodo Wallonie coordonne lʼinitiative #wallcode portée par
                Digital Wallonia.
              </p>
              <img src="img/core/work-01.jpeg" alt="photographie" />
              <span className="credit">© Kodo Wallonie</span>
              <p className="text txt">
                Cette opération vise à initier les élèves et professeurs wallons
                aux sciences informatiques, à la logique algorithmique et aux
                langages de programmation. Dans ce cadre, lʼéquipe de Kodo
                Wallonie va dans les écoles primaires, secondaires et même dans
                le supérieur afin de donner des ateliers de quelques heures pour
                comprendre, décrypter, voire même susciter des vocations ! Ces
                activités sont gratuites et sont également offertes par nos
                partenaires que vous retrouverez sur le site Digital Wallonia.
              </p>
              <ul>
                <li className="text txt">
                  Nous proposons des ateliers scolaires dès la 3e maternelle et
                  jusque dans le supérieur pédagogique afin d’initier les jeunes
                  aux sciences informatiques, en commençant par la logique
                  algorithmique et la robotique. Selon les demandes et besoins,
                  Kodo Wallonie se déplace ou vous dirige vers ses partenaires
                  pour des animations ou des formations plus adéquates.
                </li>
                <li className="text txt">
                  Selon vos demandes, nous pouvons réaliser des ateliers
                  parascolaires durant les temps de midi ou encore à la fin de
                  la journée, au sein de l’établissement. Contrairement aux
                  animations scolaires gratuites sans condition, les activités
                  demandées dans ce cadre seront éventuellement soumises à une
                  participation aux frais pour favoriser la présence de tous les
                  inscrits à ces ateliers mais aussi pour assumer les frais liés
                  à ceux-ci.
                </li>
                <li className="text txt">
                  Kodo Wallonie est à votre disposition pour vous informer des
                  outils, ressources et formations dont vous avez droit et que
                  vous pouvez utiliser lors d’animations autour du numérique.
                  Vous pouvez nous contacter via le formulaire ou encore en nous
                  demandant une séance d’information d’environ 1 à 3h au sein de
                  votre établissement.
                </li>
              </ul>
              <h2 className="secondtitle">Notre expertise</h2>
              <ul>
                <li className="text">
                  Plus de 300 ateliers déjà réalisés dont une majorité en écoles
                  primaires.
                </li>
                <li className="text">
                  Des écoles de Tournai, Attert, Charleroi, Liège, Verviers et
                  plus encore visitées depuis 2017.
                </li>
                <li className="text">
                  Accompagnement de l’équipe par des partenaires experts en
                  didactique et en sciences informatiques dans le cadre de
                  l’initiative #wallcode.
                </li>
              </ul>
              {/*
              <h2 className="secondtitle">Nos offres d'animations</h2>
              <div className="workshop-links">
                <a href="pdf/jeu_video.pdf" target="_blank" rel="noreferrer">
                  Création d'un jeu vidéo
                </a>
                <a href="pdf/robotique.pdf" target="_blank" rel="noreferrer">
                  La robotique
                </a>
                <a href="pdf/microbit.pdf" target="_blank" rel="noreferrer">
                  Micro:Bit
                </a>
                <a href="pdf/arduino.pdf" target="_blank" rel="noreferrer">
                  Arduino
                </a>
                <a href="pdf/atelier_2d.pdf" target="_blank" rel="noreferrer">
                  Atelier 2D
                </a>
                <a href="pdf/stop_motion.pdf" target="_blank" rel="noreferrer">
                  Création Stop-Motion
                </a>
                <a
                  href="pdf/minecraft_education.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Minecraft Education
                </a>
                <a href="pdf/numerique.pdf" target="_blank" rel="noreferrer">
                  Initiation au numérique
                </a>
                <a href="pdf/scratch.pdf" target="_blank" rel="noreferrer">
                  Initiation Scratch
                </a>
  </div>*/}
            </div>
          </div>
          <div className="wrapper">
            <div className="ma">
              <button className="link-btn" onClick={() => navigate(-1)}>
                ⇦ Retour
              </button>
            </div>
          </div>
        </section>
      </OnScreen>
    </>
  );
};

export default ExpSchools;
