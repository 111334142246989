import { useNavigate } from 'react-router-dom';
import SourceButton from '../comp/SourceButton';
import SourceContent from '../comp/SourceContent';
import dataLink from '../data/dataLink';
import OnScreen from '../hooks/OnScreen';
import { useState } from 'react';

const Source = () => {
  let navigate = useNavigate();
  const allCategories = [
    'Tout',
    ...new Set(dataLink.map((item) => item.category)),
  ];
  const [menuItem, setMenuItem] = useState(dataLink);
  const [buttons] = useState(allCategories);
  const filter = (button) => {
    if (button === 'Tout') {
      setMenuItem(dataLink);
      return;
    }

    const filteredData = dataLink.filter((item) => item.category === button);
    setMenuItem(filteredData);
  };

  return (
    <>
      <OnScreen>
        <section className="section">
          <div className="wrapper">
            <SourceButton button={buttons} filter={filter} />
          </div>
          <div className="wrapper">
            <div>
              <SourceContent menuItem={menuItem} />
            </div>
          </div>
          <div className="wrapper">
            <div className="ma">
              <button className="link-btn" onClick={() => navigate(-1)}>
                ⇦ Retour
              </button>
            </div>
          </div>
        </section>
      </OnScreen>
    </>
  );
};

export default Source;
