import { useNavigate } from 'react-router-dom';
import OnScreen from '../hooks/OnScreen';

const ExpCompanies = () => {
  let navigate = useNavigate();
  return (
    <>
      <OnScreen>
        <section id="cultural">
          <div className="wrapper small">
            <div className="container column">
              <h2 className="title-alt">
                Nos activités auprès des entreprises
              </h2>
              <p className="chapo">
                Les entreprises font face à la révolution digitale tout comme le
                reste de la société. Il y a aussi les organismes qui œuvrent
                dans les secteurs IT, que ce soit dans le développement ou
                l’infrastructure.
              </p>
              <img className="cross" src="img/core/work-04.jpg" alt="cross" />
              <span className="credit">© Van Tay Media - Unsplash</span>
              <p className="text txt">
                Entre le secteur associatif dont nous faisons partie et le monde
                de l’entreprise, peu de dialogue s’instaure et encore moins sur
                les thématiques du numérique. Avec Kodo Wallonie, nous désirons
                changer cela grâce à la mise en place d’activités au sein de vos
                entreprises dans le domaine des sciences informatiques.
              </p>
              <ul>
                <li className="text txt">
                  Nous désirons mettre en place un dialogue entre les
                  entreprises quelles qu’elles soient et le monde associatif :
                  demande d’expertise et d’informations, possibilité d’ateliers
                  pour les enfants des employé·es ou de formation à destination
                  du personnel. Nous voulons également faciliter la
                  communication avec les entreprises technologiques pour mieux
                  comprendre leurs besoins humains au fur et à mesure afin de
                  pouvoir les relayer auprès de nos publics, notamment les
                  adolescents et leurs parents.
                </li>
                <li className="text txt">
                  Les entreprises sont aussi un réel moteur économique de la
                  société, c’est pourquoi nous trouvons important de les
                  solliciter tant sur le plan de l’expertise-métier que nous
                  aimerions développer main dans le main avec eux que sur le
                  plan matériel afin que l’association et ses partenaires
                  puissent être en phase avec les réalités de terrain : machines
                  performantes, outils et ressources à la pointe, ... Si vous
                  désirez travailler avec nous, nous serions heureux de créer un
                  partenariat solide et de nous associer à votre réflexion et à
                  vos actions.
                </li>
              </ul>
              <h2 className="secondtitle">Notre expertise</h2>
              <p className="text txt">
                Une partie de l’équipe de Kodo Wallonie fait partie du réseau
                des Digital Wallonia Champions et travaille en collaboration
                avec plusieurs d’entre eux dans divers domaines.
              </p>
            </div>
          </div>
          <div className="wrapper">
            <div className="ma">
              <button className="link-btn" onClick={() => navigate(-1)}>
                ⇦ Retour
              </button>
            </div>
          </div>
        </section>
      </OnScreen>
    </>
  );
};

export default ExpCompanies;
