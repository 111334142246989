const SourceButton = ({ button, filter }) => {
  return (
    <div className="buttons">
      {button.map((cat, index) => {
        return (
          <button type="button" key={index} onClick={() => filter(cat)}>
            {cat}
          </button>
        );
      })}
    </div>
  );
};

export default SourceButton;
