import OnScreen from '../hooks/OnScreen';

const STEAMagine = () => {
  return (
    <>
      <OnScreen>
        <section className="section">
          <div className="wrapper small">
            <div className="section-title">
              <h2 className="title-alt">
                Apprenez à former vos élèves aux compétences numériques du XXIe
                siècle
              </h2>
            </div>
            <p className="text">
              Le projet STEAMagine vise à former plus de 500 enseignant.es et
              animateurs.trices jeunesse à construire de manière autonome des
              dispositifs STEAM, d'acculturation des jeunes au numérique at aux
              compétences de 21e siècle. Avec une attention spécifique aux
              enjeux de genre et à l'orientation des métiers.
            </p>
            <img
              className="img-full"
              src="img/steamagine.png"
              alt="STEAMagine"
            />
            <p className="title-cat">
              STEAMagine propose gratuitement un dispositif complet et gratuit
              via :
            </p>
            <p className="text"></p>
            <ol className="text">
              <li>
                des formations (utiliser le FMTTN, former aux genres et métiers
                du numérique, décourir le numérique et les modèles éducatifs,
                ...),
              </li>
              <li>des accompagnements sur site,</li>
              <li>
                des webinaires (éduquer au numérique et par le numérique,
                encourager la créativité avec les STEAM, ...)
              </li>
              <li>des événements,</li>
              <li>
                une valise pédagogique pour sensibiliser à la notion de genre et
                orientant vers les métiers de l'informatique
              </li>
              <li>
                des pratiques d'outils au sein d'une communauté de pratique
                (Scratch, Micro:bit, programmation de robots, Moiki, ChatGPT,
                Minecraft Education, ...)
              </li>
            </ol>
            <p className="title-cat">Ressources du projet :</p>
            <iframe
              className="iframe-support"
              src="https://digipad.app/p/538354/0a2e15d51b372"
              frameborder="0"
              width="100%"
              height="500"
              title="iframe"
            ></iframe>
            <p className="title-cat">Partenaires :</p>
            <div className="container col-2">
              <a
                href="https://edu-lab.be/"
                target="_blank"
                rel="noreferrer"
                className="rnig04d673 g200mv1sa1 support"
              >
                <img src="img/edulab.png" alt="edulab" />
              </a>
              <a
                href="https://www.interface3namur.be/"
                target="_blank"
                rel="noreferrer"
                className="rnig04d673 g200mv1sa1 support"
              >
                <img src="img/interface3.png" alt="interface3" />
              </a>
            </div>
            <div className="container col-2">
              <a
                href="https://web.umons.ac.be/fr/"
                target="_blank"
                rel="noreferrer"
                className="rnig04d673 g200mv1sa1 support"
              >
                <img src="img/umons.png" alt="umons" />
              </a>
              <a
                href="https://www.forj.be/"
                target="_blank"
                rel="noreferrer"
                className="rnig04d673 g200mv1sa1 support"
              >
                <img src="img/forj.png" alt="forj" />
              </a>
            </div>
            <div className="wrapper-soutien">
              <img className="soutien" src="img/soutien.png" alt="soutien" />
            </div>
          </div>
        </section>
      </OnScreen>
    </>
  );
};

export default STEAMagine;
