import OnScreen from '../hooks/OnScreen';
import { useNavigate } from 'react-router-dom';

const Soon = () => {
  let navigate = useNavigate();
  return (
    <>
      <OnScreen>
        <section className="section center">
          <div className="wrapper">
            <div className="section-title">
              <h2 className="title-alt">Coming Soon</h2>
            </div>
            <div className="container margin">
              <p>
                Nous travaillons à la réalisation d'un catalogue d'activités
                plus pertinent à vous proposer.
                <br />
                Il sera disponible d'ici quelques semaines.
              </p>
              <p>
                Pour toute demande d'animation, vous pouvez nous contacter sur
              </p>
              <a href="mailto:contact@kodowallonie.be">
                contact@kodowallonie.be
              </a>
            </div>
          </div>
          <div className="wrapper">
            <div className="ma">
              <button className="link-btn" onClick={() => navigate(-1)}>
                ⇦ Retour
              </button>
            </div>
          </div>
        </section>
      </OnScreen>
    </>
  );
};

export default Soon;
