import OnScreen from '../hooks/OnScreen';
import { Link } from 'react-router-dom';
import '../styles/_error.css';

const Error = () => {
  return (
    <>
      <OnScreen>
        <section id="error">
          <div className="wrapper">
            <img className="error" src="svg/404.svg" alt="404" />
            <div className="flex">
              <Link to="/" className="link-btn error-link">
                Retour
              </Link>
            </div>
          </div>
        </section>
      </OnScreen>
    </>
  );
};

export default Error;
