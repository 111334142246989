import { useNavigate } from 'react-router-dom';
import OnScreen from '../hooks/OnScreen';

const ExpCultural = () => {
  let navigate = useNavigate();
  return (
    <>
      <OnScreen>
        <section id="cultural">
          <div className="wrapper small">
            <div className="container column">
              <h2 className="title-alt">Nos activités auprès du culturel</h2>
              <p className="chapo">
                Les technologies font partie intégrante de notre quotidien et
                interrogent petits et grands sur leur utilisation, leur intérêt
                et leur pertinence dans différents contextes et notamment dans
                les familles.
              </p>
              <img className="cross" src="img/core/work-03.jpg" alt="cross" />
              <span className="credit">© Kodo Wallonie</span>
              <p className="text txt">
                Kodo Wallonie ayant pour but de réduire la fracture numérique,
                nous accompagnons les institutions le désirant dans la médiation
                dans ce domaine avec des thématiques variées allant du jeu vidéo
                à la robotique en passant par la logique algorithmique ou encore
                les arts numériques.
              </p>
              <ul>
                <li className="text txt">
                  Afin d’enrichir vos événements, portes ouvertes ou moments
                  thématiques, nous sommes disponibles afin de vous proposer nos
                  activités et stands participatifs durant une journée ou plus.
                  Nous disposons du matériel nécessaire pour cela : robots,
                  ordinateurs, projecteur, ... .
                </li>
                <li className="text txt">
                  Nous vous proposons la co-animation ou l’animation totale de
                  stages numériques en accord avec vos besoins et vos publics.
                  Notre volonté est de permettre un accès le plus large possible
                  à ces rencontres : c’est pourquoi nos tarifs restent les plus
                  avantageux possibles et sont fonction des besoins en animation
                  et des distances parcourues. Nous pouvons également vous
                  mettre en contact avec des organismes proches de votre lieu
                  qui seront à même de répondre à votre demande si tel n’est pas
                  notre cas.
                </li>
                <li className="text txt">
                  Vous souhaitez des moments de partage et de découverte
                  réguliers dans votre espace ? Un rendez-vous mensuel ou
                  hebdomadaire pour les jeunes ou leurs familles ? Nous animons
                  également cela sur demande et selon nos disponibilités dans
                  les mêmes conditions que les stages numériques.
                </li>
              </ul>
              <h2 className="secondtitle">Notre expertise</h2>
              <ul>
                <li className="text">
                  Les centres culturels et maisons de jeunes de Huy,
                  Braine-l’Alleud, Welkenraedt, Ottignies et d’autres visités
                  entre 2017 et 2019.
                </li>
                <li className="text">
                  Plus de 700 jeunes animés lors de ces rencontres.
                </li>
                <li className="text">
                  Plus de 40 activités extra-scolaires proposées à toutes et
                  tous dès 7 ans.
                </li>
              </ul>
            </div>
          </div>
          <div className="wrapper">
            <div className="ma">
              <button className="link-btn" onClick={() => navigate(-1)}>
                ⇦ Retour
              </button>
            </div>
          </div>
        </section>
      </OnScreen>
    </>
  );
};

export default ExpCultural;
